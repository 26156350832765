var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"clav-info-content",attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-n2 mb-3 mx-6 font-weight-medium",attrs:{"append-icon":"search","label":"Procurar / filtrar tabela","text":"","single-line":"","hide-details":"","clearable":"","color":"blue darken-3"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},on))]}}])},[_c('span',[_vm._v(" Filtrar tabela")])]),(_vm.tipo == 'TABELAS DE SELEÇÃO INSERIDAS NA CLAV')?_c('v-data-table',{staticClass:"content-table",attrs:{"headers":_vm.headers,"items":_vm.lista,"search":_vm.search,"footer-props":_vm.footer_props},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium my-3",attrs:{"value":true,"color":"error","icon":"warning","id":"alerta-erro"}},[_vm._v("Não foram encontrados resultados para \""+_vm._s(_vm.search)+"\".")])]},proxy:true},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.$router.push('/ts/' + item.id)}}},on),[_c('unicon',{attrs:{"name":"look-icon","width":"25","height":"25","viewBox":"0 0 20.71 15.574","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Ver Tabela de Seleção")])])]}},{key:"item.entidades",fn:function(ref){
var item = ref.item;
return _vm._l((item.entidades),function(ent,index){return _c('span',{key:index},[_c('a',{attrs:{"href":_vm.link(ent)}},[_vm._v(_vm._s(ent))]),_vm._v(_vm._s(item.entidades.length > 1 ? " " : ""))])})}},{key:"pageText",fn:function(props){return [_vm._v(" Resultados: "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}],null,true)}):(_vm.tipo == 'RADA')?_c('v-data-table',{staticClass:"content-table",attrs:{"headers":_vm.headers,"items":_vm.lista,"search":_vm.search,"footer-props":_vm.footer_props},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium my-3",attrs:{"value":true,"color":"error","icon":"warning","id":"alerta-erro"}},[_vm._v("Não foram encontrados resultados para \""+_vm._s(_vm.search)+"\".")])]},proxy:true},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.link)?_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.link.startsWith('CLAV'))?_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":"","href":_vm.pathAPI +
              '/ficheirosEstaticos?caminho=documentos%2FRADA%2FDespacho' +
              item.link.split('Despacho')[1] +
              '&' +
              _vm.authToken}},on),[_c('unicon',{attrs:{"name":"pdf-icon","width":"25","height":"25","viewBox":"0 0 20.71 23.668","fill":"#C62828"}})],1):_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":"","href":item.link}},on),[_c('unicon',{attrs:{"name":"pdf-icon","width":"25","height":"25","viewBox":"0 0 20.71 23.668","fill":"#C62828"}})],1)]}}],null,true)},[_c('span',[_vm._v("Aceder à TS do RADA em PDF")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.$router.push('/pgd/' + item.idRADA)}}},on),[_c('unicon',{attrs:{"name":"look-icon","width":"25","height":"25","viewBox":"0 0 20.71 15.574","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Ver RADA")])])]}},{key:"pageText",fn:function(props){return [_vm._v(" Resultados: "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}],null,true)}):_c('v-data-table',{staticClass:"content-table",attrs:{"headers":_vm.headers,"items":_vm.lista,"search":_vm.search,"footer-props":_vm.footer_props},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium my-3",attrs:{"value":true,"color":"error","icon":"warning","id":"alerta-erro"}},[_vm._v("Não foram encontrados resultados para \""+_vm._s(_vm.search)+"\".")])]},proxy:true},{key:"item.link",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":"","href":item.link}},on),[_c('unicon',{attrs:{"name":"pdf-icon","width":"25","height":"25","viewBox":"0 0 20.71 23.668","fill":"#C62828"}})],1)]}}],null,true)},[_c('span',[_vm._v("Aceder a Diário da República")])]),(item.idPGD)?_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.$router.push('/pgd/' + item.idPGD)}}},on),[_c('unicon',{attrs:{"name":"look-icon","width":"25","height":"25","viewBox":"0 0 20.71 15.574","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Ver Portaria")])]):_vm._e()]}},{key:"item.entidades",fn:function(ref){
              var item = ref.item;
return _vm._l((item.entidades),function(ent,index){return _c('span',{key:index},[_c('a',{attrs:{"href":_vm.link(ent)}},[_vm._v(_vm._s(ent))]),_vm._v(_vm._s(item.entidades.length > 1 ? " " : ""))])})}},{key:"pageText",fn:function(props){return [_vm._v(" Resultados: "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }